
import { defineComponent, reactive, ref } from 'vue';
import MyInput from "@/components/input/Input.vue";
import { ElMessageBox, ElMessage } from 'element-plus';
import { tuenParam, stringToArr } from '@/data/myfunc';
import Config from '@/data/config';
export default defineComponent({
  components: { MyInput },
  props: {
    showdalog: {
      type: Boolean,
      default: false,
    },
    dialogtitle: {
      type: String,
      default: "",
    },
    visible: {
      type: Function,
      default: () => { },
    },
    mod: {
      //0 add 1 查看 2 修改
      type: Number,
      default: 0,
    },
    mid: {
      //操作的列表id
      type: Number,
      default: -1,
    },
    aipfunction: {
      //接口函数
      type: Function,
      default: async () => { },
    },
    menuName: {
      type: String,
      default: "",
    },
  },
  setup(props) {


    var formdata = reactive<any>([]);
    let first_updata = false;
    const loading = ref(true);
    const dialogVisible = ref(false);
    async function getInfo(type, d) {
      let data = await props.aipfunction({ menu_name: props.menuName, type: type, data: d });

      if (data == null || !data) {
        ElMessage.error('请检查权限！');
        loading.value = false
        props.visible(false);
        return
      }
      formdata.splice(0, formdata.length);
      for (let i = 0; i < data.length; i++) {
        let a = data[i];
        if (props.mod == 1) {
          a.disable = true;
        }
        if (props.mod == 0 || props.mod == 2) {
          a.disable = false;
        }
        formdata.push(a);
      }
      loading.value = false;
    }

    const handleClose = (done: () => void) => {
      ElMessageBox.confirm('确认放弃修改并关闭对话框吗?', '警告', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
        draggable: true,
      })
        .then(() => {
          done();
          props.visible(false);
        })
        .catch(() => {
          // catch error
        });
    };
    //表单的数据验证
    function validrules(val, type = 'empty') {
      if (type == 'empty' && val === '') {
        //非空
        return false;
      }
      if (type == 'zero' && (val === 0 || val === '' || val === null)) {
        //非0
        return false;
      }
      if (type == 'thanzero' && (val <= 0 || val === '' || val === null)) {
        //非负
        return false;
      }
      return true;
    }
    async function submit() {
      if (loading.value) {
        return;
      }
      let r = true
      loading.value = true;
      if (props.mod != 1) {
        if (!formdataValid()) {
          ElMessage.error('表单数据验证失败！');
          loading.value = false;
          return;
        }
        if (props.mod == 0) {
          let d = tuenParam(formdata);
          console.log(d, 'submit', first_updata);
          r = await props.aipfunction({ menu_name: props.menuName, type: 'add', data: d });
        }
        if (props.mod == 2) {
          let d = tuenParam(formdata, true);
          console.log(d, 'submit', first_updata);
          if (first_updata) {
            r = await props.aipfunction({ menu_name: props.menuName, type: 'edit', data: { mid: props.mid, data: d } });
          }
        }
      }
      console.log(r);
      loading.value = false;
      if (r || r == null) {
        if (props.mod != 1) {
          ElMessage.success('操作成功');
        }
        props.visible(false);
        return
      }

    }
    //验证所有表单数据
    function formdataValid() {
      let r = true;
      for (let i = 0; i < formdata.length; i++) {
        if (formdata[i].valid != '' && !validrules(formdata[i].value, formdata[i].valid)) {
          formdata[i].validok = false;
          setTimeout(() => {
            formdata[i].validok = true;
          }, 2000);
          r = false;
        } else {
          formdata[i].validok = true;
        }
      }
      return r;
    }
    //每次更新值时都要去验证一下值是否符合规则
    function inputUpdata(i, v) {
      if (formdata[i].value == v) {
        return
      }
      formdata[i].value = v;
      if (formdata[i].valid != '') {
        if (validrules(v, formdata[i].valid)) {
          formdata[i].updata = true;
          first_updata = true;
          formdata[i].validok = true;
        } else {
          formdata[i].validok = false;
          setTimeout(() => {
            formdata[i].validok = true;
          }, 2000);
        }
      } else {
        formdata[i].validok = true;
        formdata[i].updata = true;
        first_updata = true;
      }

      if (v == null && formdata[i].component == 'number') {
        formdata[i].value = 0;
      }
    }
    //远程搜索
    async function func_remote(index: number, searchval: string) {
      let data = await props.aipfunction({ menu_name: props.menuName, type: "remote", data: { wallet_addr: searchval } });
      formdata[index].options = data
    }
    return {
      func_remote,
      first_updata,
      loading,
      formdata,
      dialogVisible,
      getInfo,
      submit,
      inputUpdata,
      handleClose,
    };
  },
  watch: {
    showdalog(n) {
      this.dialogVisible = n;
      this.loading = true;
      if (n) {
        console.log(this.mod, 'mod ');
        if (this.mod == 0) {
          this.getInfo('add', "null");
        }
        if (this.mod == 1 || this.mod == 2) {
          this.getInfo('look', { mid: this.mid, mod: this.mod });
        }
      }
    },
  },
});
