
//https://www.wangeditor.com/v5/API.html#movereverse
import '@wangeditor/editor/dist/css/style.css' // 引入 css

import { onBeforeUnmount, shallowRef, ref, watch } from 'vue'
import { Editor, Toolbar } from "@wangeditor/editor-for-vue"
import { IEditorConfig } from '@wangeditor/editor'
import { ElMessage } from 'element-plus';
import Data from "@/data/data";
type InsertFnType = (url: string, alt: string, href: string) => void
export default {
  props: {
    value: {
      type: String,
      default: ""
    },
    disable: {
      type: Boolean,
      default: false
    }
  },
  components: { Editor, Toolbar },
  setup(props, content) {

    // 编辑器实例，必须用 shallowRef
    const valueHtml = ref(props.value)
    watch(
      valueHtml,
      (n) => {
        content.emit("change", n)
      }
    )
    const editorRef = shallowRef()

    const toolbarConfig = {}
    const editorConfig: Partial<IEditorConfig> = {  // TS 语法
      placeholder: '请输入内容...',
      readOnly: props.disable,
      MENU_CONF: {}

      // 其他属性...
    }
    if (editorConfig.MENU_CONF) {
      editorConfig.MENU_CONF['codeSelectLang'] = {
        // 代码语言
        codeLangs: [
          { text: 'CSS', value: 'css' },
          { text: 'HTML', value: 'html' },
          { text: 'XML', value: 'xml' },
          // 其他
        ]
      }
      editorConfig.MENU_CONF['uploadImage'] = {
        server: Data.upload,
        // form-data fieldName ，默认值 'wangeditor-uploaded-image'
        fieldName: 'upload-file',

        // 单个文件的最大体积限制，默认为 2M
        maxFileSize: 1 * 1024 * 1024, // 1M

        // 最多可上传几个文件，默认为 100
        maxNumberOfFiles: 10,

        // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
        allowedFileTypes: ['image/*'],

        // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
        meta: {
          token: 'xxx',
          otherKey: 'yyy'
        },

        // 将 meta 拼接到 url 参数中，默认 false
        metaWithUrl: false,

        // 自定义增加 http  header
        headers: {
          Accept: 'text/x-json',
          otherKey: 'xxx'
        },

        // 跨域是否传递 cookie ，默认为 false
        withCredentials: true,

        // 超时时间，默认为 10 秒
        timeout: 5 * 1000, // 5 秒
        // 上传之前触发
        onBeforeUpload(file) { // TS 语法
          // onBeforeUpload(file) {    // JS 语法
          // file 选中的文件，格式如 { key: file }
          return file

          // 可以 return
          // 1. return file 或者 new 一个 file ，接下来将上传
          // 2. return false ，不上传这个 file
        },

        // 上传进度的回调函数
        onProgress(progress) {  // TS 语法
          // onProgress(progress) {       // JS 语法
          // progress 是 0-100 的数字
          console.log('progress', progress)
        },
        customInsert(res: any, insertFn: InsertFnType) {  // TS 语法
          // customInsert(res, insertFn) {                  // JS 语法
          // res 即服务端的返回结果
          console.log(res);
          if (res.code === 0) {
            console.log("上传成功");
            ElMessage({
              message: '上传成功.',
              type: 'success',
            });

            // 从 res 中找到 url alt href ，然后插图图片
            insertFn(Data.prf_image + res.result, "", Data.prf_image + res.result)
          } else {
            ElMessage.error(res.result);
          }

        },
      }
    }
    // 组件销毁时，也及时销毁编辑器
    onBeforeUnmount(() => {
      const editor = editorRef.value
      if (editor == null) return
      editor.destroy()
    })

    const handleCreated = (editor) => {
      editorRef.value = editor // 记录 editor 实例，重要！
    }

    return {
      editorRef,
      mode: 'default', // 或 'simple'
      toolbarConfig,
      editorConfig,
      handleCreated,
      valueHtml
    };
  },
  // watch: {
  //   value(n:string) {
  //     // console.log("value ", n);
  //     if (n == "") {
  //       this.valueHtml = "<p><br></p>"
  //     } else {
  //       this.valueHtml = n
  //     }
  //   }
  // }
}
