import { CacheConfigOption, CacheData } from "../../interface/interface"
import { deepMerge } from "../Leo"
export class Cache {
    private _config: CacheConfigOption
    public set config(v: CacheConfigOption) {
        this._config = v
    }
    public get config(): CacheConfigOption {
        return this._config
    }
    private leoStoragePool: string[] = []
    private timerID = -1
    private onExpire: Function = () => { }//过期
    constructor(opt: CacheConfigOption) {
        this._config = opt
        const d = localStorage.getItem(opt.storagepool)
        if (d) {
            const data = JSON.parse(d)
            this.leoStoragePool = data
        }
        //每分钟去检查缓存是否过期
        this.timerID = setInterval(this.check_expire.bind(this), opt.check_expire_time)
    }
    private check_expire() {
        const now_time = new Date().getTime()
        let expire_item: string[] = []
        for (let i = 0; i < this.leoStoragePool.length; i++) {
            let a = this.leoStoragePool[i]
            let d = localStorage.getItem(a)
            if (d) {
                let data = JSON.parse(d) as CacheData
                if (data.exp_time && data.exp_time < now_time) {
                    expire_item.push(data.key)
                    //已经过期了 清理

                }
            }
        }
        for (let i = 0; i < expire_item.length; i++) {
            localStorage.removeItem(expire_item[i])
            this.del_pool(expire_item[i])
            this.onExpire(expire_item[i])
        }

    }
    private add_pool(key) {
        //判断是否保存过相同的key
        let have = false
        for (let i = 0; i < this.leoStoragePool.length; i++) {
            if (this.leoStoragePool[i] == key) {
                have = true
                break
            }
        }
        if (!have) {
            this.leoStoragePool.push(key)
            this.updata_local_pool()
        }
    }
    private del_pool(key): boolean {
        for (let i = 0; i < this.leoStoragePool.length; i++) {
            if (this.leoStoragePool[i] == key) {
                this.leoStoragePool.splice(i, 1)
                this.updata_local_pool()
                return true
            }
        }
        return false
    }
    private updata_local_pool() {
        localStorage.setItem(this._config.storagepool, JSON.stringify(this.leoStoragePool))
    }
    public set_item(key: string, value = ""): CacheData | null {
        if (key == "") {
            return null
        }
        const now_time = new Date().getTime()
        const exp_time = now_time + this._config.expire_time
        let d: CacheData = {
            key: this._config.prefix + key,
            value: value,
            create_time: now_time,
            exp_time: exp_time
        }

        localStorage.setItem(d.key, JSON.stringify(d))
        this.add_pool(d.key)
        return d
    }
    public get_item(key: string): CacheData | null {
        if (key == "") {
            return null
        }
        let _a = localStorage.getItem(this._config.prefix + key)
        
        if (_a) {
            let d: CacheData
            d = JSON.parse(_a)
            try{
                d.value = JSON.parse(d.value)
            }catch(e){}
            
            return d

        }
        return null
    }
    public remove_item(key: string) {
        localStorage.removeItem(this._config.prefix +key)
        this.del_pool(this._config.prefix +key)
    }
    public remove_all() {
        localStorage.clear()
        this.leoStoragePool = []
    }
    public get_pool(): string[] {
        return this.leoStoragePool
    }
}
function createStorage(opt?: CacheConfigOption) {
    return new Cache(deepMerge({
        prefix: "Leo_",
        storagepool: "LeoStorage",
        check_expire_time: 5000,
        expire_time: 24 * 60 * 60 * 1000
    }, opt || {}))
}
const LeoStorage = createStorage()
export default LeoStorage
