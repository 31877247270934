/**
 * 目录
 * inArray 判断数组是否存在某个值
 * creatJosnFile 将内容形成json文件
 * SaveFile 将html任意内容存为文件。
 * LisitionChange 设置变量，并监听变化
 * getTurnPoint 获取一点绕另一点旋转任意角度后的坐标 
 * turnR   根据一个点和圆心坐标获得角度以及半径
 * turnRpoint  获取圆内任意一点
 * getArrOneNum 给定一个整型数组，返回只出现过一次的数字
 * run_function_number 按顺序执行方法
 * random 传入一个区间，返回区间内的随机数
 * numberToChinese 将阿拉伯数字翻译成中文的大写数字
 * changeToChinese 将数字转换为大写金额
 * paramsParse  将{key:val,[]}格式转换成key=val&[]
 * judge_m_or_p  判断当前设备移动端还是pc端
 * hengshuping  判断横屏和竖屏
 * trim  去除空格
 * change_Case  大小写转换
 * checkPwd  检测密码强度
 * getIntervalDate  获取距离今天的N天的日期
 * getUrlParam  获取url后的参数
 * obj_clone  深度克隆
 * xz_get  获取星座
 * key_listener  键盘监听
 * get_type  输入一个值，返回其数据类型
 * getNo_list 数组去重
 * list_isBig 数组排序
 * checkStr  格式校验
 * isCardID  身份证格式校验
 * formatTime  格式化时间
 * getMonthOfDay  获取某月有多少天
 * getYearOfDay  获取某年有多少天
 * getFirstDayOfYear  获取某年的第一天
 * getLastDayOfYear  获取某年的最后一天
 * getDayOfYear  获取某个日期在今年的第多少天
 * getDayOfYearWeek  获取某个日期在这一年的第几周
 * send_http  发送http请求
 */
import { LeoHttp } from "../interface/interface"
export default class Leo {
	public static EditDom(dom: HTMLElement) {

	}
	/**
	 * 判断数组是否存在某个值
	 */
	public static inArray(array: any[], value: any) {
		let have = false
		let result
		array.forEach((item, index) => {
			if ((typeof value) == "object") {
				let _have = 0
				let fornum = 0
				for (let keys in value) {
					fornum += 1
					if (item[keys] == value[keys]) {
						_have += 1
					}
				}
				if (_have == fornum) {
					have = true
					result = { item: item, index: index }
					return

				}

			} else {

				if (item == value) {
					have = true
					result = { item: item, index: index }
					return
				}
			}
		})
		if (have) {
			return result
		}
		return false
	}
	/**生成json文件 */
	public static creatJosnFile(data: any, fileName: string) {
		var content = JSON.stringify(data)
		this.saveForBrowser(content, fileName)
	}

	/**
	 * 存字符串内容到文件。
	 * @param textToWrite  - 要保存的文件内容
	 * @param fileNameToSaveAs - 要保存的文件名
	 */
	public static saveForBrowser(textToWrite: string, fileNameToSaveAs: string) {
		// if (sys.isBrowser) {
		//     cc.log("浏览器");

		let textFileAsBlob = new Blob([textToWrite], { type: 'application/json' });
		let downloadLink = document.createElement("a");
		downloadLink.download = fileNameToSaveAs;
		downloadLink.innerHTML = "Download File";
		if (window.webkitURL != null) {
			downloadLink.href = window.webkitURL.createObjectURL(textFileAsBlob);
		}
		downloadLink.click();
		// }
	}
	/**
	 * 
	 * @param name 保存的文件名
	 * @param content 文件的内容
	 * @param type 类型
	 */
	public static SaveFile(name: string, content: string, type = "text/plain;charset=utf-8") {
		const blob = new Blob([content], { type: type })
		const objectURL = URL.createObjectURL(blob)
		const anchor = document.createElement('a')
		anchor.href = objectURL
		anchor.download = name
		anchor.click()
		URL.revokeObjectURL(objectURL)
	}
	/**
 * 
 * @param obj 要被监听的数据
 * @param e 监听的方法
 * @returns 返回一个Prox对象
 */
	public static LisitionChange(obj: { [key: string]: any }, e?: Function) {
		if (typeof obj === "boolean" || typeof obj === "string" || typeof obj === "number") {
			return new Proxy({ value: obj }, {
				get(obj: { [key: string]: any }, key: any) {
					if (e) {
						e("get", obj, key, obj[key])
					}
					return obj[key]
				},
				set(obj: { [key: string]: any }, key: any, val) {
					if (e) {
						e("set", obj, key, val)
					}
					obj[key] = val
					return true
				}
			})
		}
		if (typeof obj === "object") {
			return new Proxy(obj, {
				get(obj: { [key: string]: any }, key: any) {
					if (e) {
						e("get", obj, key, obj[key])
					}
					return obj[key]
				},
				set(obj: { [key: string]: any }, key: any, val) {
					if (e) {
						e("set", obj, key, val)
					}
					obj[key] = val
					return true
				}
			})
		}

	}
	/**
	 * 获取一点绕另一点旋转任意角度后的坐标
	 * @param rx 原点x
	 * @param ry 原点y
	 * @param px 旋转的点的x
	 * @param py 旋转的点的y
	 * @param r  旋转的角度
	 */
	public static getTurnPoint(rx: number, ry: number, px: number, py: number, r: number): { x: number, y: number } {
		r = r * Math.PI / 180
		let x = (px - rx) * Math.cos(r) - (py - ry) * Math.sin(r) + rx

		let y = (px - rx) * Math.sin(r) + (py - ry) * Math.cos(r) + ry
		let p: { x: number, y: number } = {
			x: 0, y: 0
		}
		p.x = x
		p.y = y
		return p
	}
	/**
	 * 根据一个点和圆心坐标获得半径、角度
	 * @param px 
	 * @param py 
	 * @param rpx 
	 * @param rpy 
	 * @returns 
	 */
	public static turnR(px: number, py: number, rpx: number, rpy: number): { leng: number, r: number } {
		let c = Math.sqrt(px * px + py * py)
		let ro = Math.atan((px - rpx) / (py - rpy)) * 180 / Math.PI
		if (px >= 0 && py >= 0) {
			//第一象限
			// console.log("第一象限");

		} else if (px <= 0 && py >= 0) {
			//第二象限
			ro = 360 + ro
			// console.log("第二象限");
		} else if (px <= 0 && py <= 0) {
			//第三象限
			ro = 180 + ro
			// console.log("第三象限");
		} else if (px >= 0 && py <= 0) {
			//第四象限
			ro = 180 + ro
			// console.log("第四象限");
		}
		return { leng: c, r: ro }
	}
	/**
	 * 获取圆上任意一点
	 * @param rx 
	 * @param ry 
	 * @param r 半径
	 * @param a 角度
	 * @returns 
	 */
	public static turnRpoint(rx: number, ry: number, r: number, a: number): { x: number, y: number } {
		let x = rx + r * Math.cos(a)
		let y = ry + r * Math.sin(a)
		return { x: x, y: y }
	}
	/**
	 * 给定一个数字数组，返回只出现一次的数字
	 * @param arr  数组
	 */
	public static getArrOneNum(arr: number[]): number {
		let ans = arr[0];
		if (arr.length > 1) {
			for (let i = 1; i < arr.length; i++) {
				ans = ans ^ arr[i];
			}
		}
		return ans;
	}
	/**
	 * 按顺序执行方法
	 * @param F 存放按先后顺序执行的函数
	 */
	public static async run_function_number(F: Function[]) {
		for (let i = 0; i < F.length; i++) {
			await test(F[i])
		}
		function test(f: Function) {
			return new Promise((resolve, reject) => {
				try {
					f()
					resolve(true)
				} catch (e) {
					reject(e)
				}

			})
		}
	}

	/**传入一个区间，返回区间内的随机数
	* 包含下界，包含上界
	*  @param min 区间下界
	* @param max 区间上界
	*/
	public static random(min: number, max: number): number {
		if (arguments.length === 2) {
			return Math.floor(min + Math.random() * ((max + 1) - min))
		} else {
			return -1;
		}
	}

	/**
	 * 将阿拉伯数字翻译成中文的大写数字(带分位，如几百零几)
	 * @param num string or number
	 */
	public static numberToChinese(num: number): string {
		var AA = new Array("零", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十");
		var BB = new Array("", "十", "百", "仟", "萬", "億", "点", "");
		var a = ("" + num).replace(/(^0*)/g, "").split("."),
			k = 0,
			re = "";
		for (var i = a[0].length - 1; i >= 0; i--) {
			switch (k) {
				case 0:
					re = BB[7] + re;
					break;
				case 4:
					if (!new RegExp("0{4}//d{" + (a[0].length - i - 1) + "}$")
						.test(a[0]))
						re = BB[4] + re;
					break;
				case 8:
					re = BB[5] + re;
					BB[7] = BB[5];
					k = 0;
					break;
			}
			if (k % 4 == 2 && a[0].charAt(i + 2) != '0' && a[0].charAt(i + 1) == '0')
				re = AA[0] + re;
			if (a[0].charAt(i) != '0')
				re = AA[Number(a[0].charAt(i))] + BB[k % 4] + re;
			k++;
		}
		if (a.length > 1) // 加上小数部分(如果有小数部分)
		{
			re += BB[6];
			for (var i = 0; i < a[1].length; i++)
				re += AA[Number(a[1].charAt(i))];
		}
		if (re == '一十')
			re = "十";
		if (re.match(/^一/) && re.length == 3)
			re = re.replace("一", "");
		return re;
	}

	/**
	 * 将数字转换为大写金额
	 * @param Num 
	 */
	public static changeToChinese(Num: any) {
		//判断如果传递进来的不是字符的话转换为字符
		if (typeof Num == "number") {
			Num = new String(Num);
		};
		Num = Num.replace(/,/g, "") //替换tomoney()中的“,”
		Num = Num.replace(/ /g, "") //替换tomoney()中的空格
		Num = Num.replace(/￥/g, "") //替换掉可能出现的￥字符
		if (isNaN(Num)) { //验证输入的字符是否为数字
			//alert("请检查小写金额是否正确");
			return "";
		};
		//字符处理完毕后开始转换，采用前后两部分分别转换
		var part = String(Num).split(".");
		var newchar = "";
		//小数点前进行转化
		for (var i = part[0].length - 1; i >= 0; i--) {
			if (part[0].length > 10) {
				return "";
				//若数量超过拾亿单位，提示
			}
			var tmpnewchar = ""
			var perchar = part[0].charAt(i);
			switch (perchar) {
				case "0":
					tmpnewchar = "零" + tmpnewchar;
					break;
				case "1":
					tmpnewchar = "壹" + tmpnewchar;
					break;
				case "2":
					tmpnewchar = "贰" + tmpnewchar;
					break;
				case "3":
					tmpnewchar = "叁" + tmpnewchar;
					break;
				case "4":
					tmpnewchar = "肆" + tmpnewchar;
					break;
				case "5":
					tmpnewchar = "伍" + tmpnewchar;
					break;
				case "6":
					tmpnewchar = "陆" + tmpnewchar;
					break;
				case "7":
					tmpnewchar = "柒" + tmpnewchar;
					break;
				case "8":
					tmpnewchar = "捌" + tmpnewchar;
					break;
				case "9":
					tmpnewchar = "玖" + tmpnewchar;
					break;
			}
			switch (part[0].length - i - 1) {
				case 0:
					tmpnewchar = tmpnewchar + "元";
					break;
				case 1:
					if (perchar != '0') tmpnewchar = tmpnewchar + "拾";
					break;
				case 2:
					if (perchar != '0') tmpnewchar = tmpnewchar + "佰";
					break;
				case 3:
					if (perchar != '0') tmpnewchar = tmpnewchar + "仟";
					break;
				case 4:
					tmpnewchar = tmpnewchar + "万";
					break;
				case 5:
					if (perchar != '0') tmpnewchar = tmpnewchar + "拾";
					break;
				case 6:
					if (perchar != '0') tmpnewchar = tmpnewchar + "佰";
					break;
				case 7:
					if (perchar != '0') tmpnewchar = tmpnewchar + "仟";
					break;
				case 8:
					tmpnewchar = tmpnewchar + "亿";
					break;
				case 9:
					tmpnewchar = tmpnewchar + "拾";
					break;
			}
			var newchar = tmpnewchar + newchar;
		}
		//小数点之后进行转化
		if (Num.indexOf(".") != -1) {
			if (part[1].length > 2) {
				// alert("小数点之后只能保留两位,系统将自动截断");
				part[1] = part[1].substr(0, 2)
			}
			for (i = 0; i < part[1].length; i++) {
				tmpnewchar = ""
				perchar = part[1].charAt(i)
				switch (perchar) {
					case "0":
						tmpnewchar = "零" + tmpnewchar;
						break;
					case "1":
						tmpnewchar = "壹" + tmpnewchar;
						break;
					case "2":
						tmpnewchar = "贰" + tmpnewchar;
						break;
					case "3":
						tmpnewchar = "叁" + tmpnewchar;
						break;
					case "4":
						tmpnewchar = "肆" + tmpnewchar;
						break;
					case "5":
						tmpnewchar = "伍" + tmpnewchar;
						break;
					case "6":
						tmpnewchar = "陆" + tmpnewchar;
						break;
					case "7":
						tmpnewchar = "柒" + tmpnewchar;
						break;
					case "8":
						tmpnewchar = "捌" + tmpnewchar;
						break;
					case "9":
						tmpnewchar = "玖" + tmpnewchar;
						break;
				}
				if (i == 0) tmpnewchar = tmpnewchar + "角";
				if (i == 1) tmpnewchar = tmpnewchar + "分";
				newchar = newchar + tmpnewchar;
			}
		}
		//替换所有无用汉字
		while (newchar.search("零零") != -1)
			newchar = newchar.replace("零零", "零");
		newchar = newchar.replace("零亿", "亿");
		newchar = newchar.replace("亿万", "亿");
		newchar = newchar.replace("零万", "万");
		newchar = newchar.replace("零元", "元");
		newchar = newchar.replace("零角", "");
		newchar = newchar.replace("零分", "");
		if (newchar.charAt(newchar.length - 1) == "元") {
			newchar = newchar + "整"
		}
		return newchar;
	}




	/**
	 * 将{key:val,[]}格式转换成key=val&[]
	 */
	public static paramsParse(args: any) {
		let str = "";
		for (let k in args) {
			let val = "";
			if ('object' == (typeof args[k])) {
				val = JSON.stringify(args[k]);
			} else {
				val = args[k];
			}
			if (str == "") {

				str = k + "=" + val;
			} else {
				str = str + "&" + k + "=" + val;
			}
		}
		return str;
	}


	/**
	 * 判断当前设备移动端还是pc端
	 * m 为移动端
	 * p 为pc端
	 * 失败则返回错误
	 */
	public static judge_m_or_p(): any {
		try {
			if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
				// console.log("移动端")
				return 'm'
			} else {
				//    console.log("pc端");
				return 'p'
			}
		} catch (e) {
			return e
		}
	}
	/**
	 * 判断横屏和竖屏,只有在移动端才能生效，否则返回undfine
	 * s 竖屏
	 * h 横批
	 */
	public static hengshuping() {
		if (window.orientation == 180 || window.orientation == 0) {
			//    alert("竖屏状态！")
			return 's'
		}
		if (window.orientation == 90 || window.orientation == -90) {
			//    alert("横屏状态！")
			return 'h'
		}
	}
	/**
 * 去除空格
 * @param {str}
 * @param {type} 
 *    type: 1-所有空格 2-前后空格 3-前空格 4-后空格
 * @return {String}
 */
	public static trim(str: string, type: number = 1) {
		type = type || 1
		switch (type) {
			case 1:
				return str.replace(/\s+/g, "");
			case 2:
				return str.replace(/(^\s*)|(\s*$)/g, "");
			case 3:
				return str.replace(/(^\s*)/g, "");
			case 4:
				return str.replace(/(\s*$)/g, "");
			default:
				return str;
		}
	}

	/**
 * @param {str} 
 * @param {type}
 *    type: 1:首字母大写 2：首页母小写 3：大小写转换 4：全部大写 5：全部小写
 * @return {String}
 */
	public static change_Case(str: string, type: number = 1) {
		type = type || 4
		switch (type) {
			case 1:
				return str.replace(/\b\w+\b/g, function (word) {
					return word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase();
				});
			case 2:
				return str.replace(/\b\w+\b/g, function (word) {
					return word.substring(0, 1).toLowerCase() + word.substring(1).toUpperCase();
				});
			case 3:
				return str.split('').map(function (word) {
					if (/[a-z]/.test(word)) {
						return word.toUpperCase();
					} else {
						return word.toLowerCase()
					}
				}).join('')
			case 4:
				return str.toUpperCase();
			case 5:
				return str.toLowerCase();
			default:
				return str;
		}
	}

	/**
	 * 检测密码强度
	 * @param str 
	 */
	public static checkPwd(str: string) {
		var Lv = 0;
		if (str.length < 6) {
			return Lv
		}
		if (/[0-9]/.test(str)) {
			Lv++
		}
		if (/[a-z]/.test(str)) {
			Lv++
		}
		if (/[A-Z]/.test(str)) {
			Lv++
		}
		if (/[\.|-|_]/.test(str)) {
			Lv++
		}
		return Lv;
	}
	/** 
	 * 获取距离今天的N天的日期  N可正可负
	 * @param {Number} interval default 0  -n 表示前几天  n表示后几天
	 */
	public static getIntervalDate(interval = 0) {
		interval = Number(interval)
		let currentDate = new Date();
		currentDate.setDate(currentDate.getDate() + interval);
		let year = currentDate.getFullYear();
		let month = (currentDate.getMonth() + 1) < 10 ? "0" + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
		let day = currentDate.getDate() < 10 ? "0" + currentDate.getDate() : currentDate.getDate();
		return year + "-" + month + "-" + day;
	}
	/**
	 * 获取url中的参数  
	 * @return {[type]} [description]
	 */
	public static getUrlParam() {
		// let search = top.location.search;
		let search = location.search;
		let _str = search && decodeURIComponent(search.replace(/^\?/, ''));
		let _arr = !_str ? [] : _str.split('&'),
			param: any = {}
		_arr.forEach(function (item) {
			let key = item.split('=')[0];
			let val = item.split('=')[1];
			param[key] = val;
		});

		return param;
	}
	/**
 * 深度克隆,返回新的对象
 * @param obj:需要克隆的对象
 */
	public static obj_clone(obj: any): any {
		const newobj: any = {};//constructor 属性返回对创建此对象的数组函数的引用。创建相同类型的空数据

		for (const i in obj) {
			if (typeof obj[i] === 'object' && !(obj[i] instanceof Array)) {//判断对象的这条属性是否为对象
				newobj[i] = this.obj_clone(obj[i]);//若是对象进行嵌套调用
			} else {
				newobj[i] = obj[i];
			}
		}

		return newobj;//返回深度克隆后的对象
	}
	/**
	 * 传入月份和几号返回星座
	 * 不传默认当前日期
	 * 返回星座
	 * @param mm 月份
	 * @param dd 日期
	 */
	public static xz_get(mm: number = 0, dd: number = 0) {
		let xztable = [
			{
				name: "水瓶座",
				start: {
					month: 1,
					day: 21
				},
				to: {
					month: 2,
					day: 19
				}
			}, {
				name: "双鱼座",
				start: {
					month: 2,
					day: 20
				},
				to: {
					month: 3,
					day: 20
				}
			}, {
				name: "白羊座",
				start: {
					month: 3,
					day: 21
				},
				to: {
					month: 4,
					day: 20
				}
			}, {
				name: "金牛座",
				start: {
					month: 4,
					day: 21
				},
				to: {
					month: 5,
					day: 21
				}
			}, {
				name: "双子座",
				start: {
					month: 5,
					day: 22
				},
				to: {
					month: 6,
					day: 21
				}
			}, {
				name: "巨蟹座",
				start: {
					month: 6,
					day: 22
				},
				to: {
					month: 7,
					day: 22
				}
			}, {
				name: "狮子座",
				start: {
					month: 7,
					day: 23
				},
				to: {
					month: 8,
					day: 22
				}
			}, {
				name: "处女座",
				start: {
					month: 8,
					day: 23
				},
				to: {
					month: 9,
					day: 22
				}
			}, {
				name: "天秤座",
				start: {
					month: 9,
					day: 23
				},
				to: {
					month: 10,
					day: 23
				}
			}, {
				name: "天蝎座",
				start: {
					month: 10,
					day: 24
				},
				to: {
					month: 11,
					day: 22
				}
			}, {
				name: "射手座",
				start: {
					month: 11,
					day: 23
				},
				to: {
					month: 12,
					day: 21
				}
			}, {
				name: "摩羯座",
				start: {
					month: 12,
					day: 22
				},
				to: {
					month: 1,
					day: 20
				}
			},
		]
		let date = new Date()
		let xz = ""
		let index = 999
		let m, d
		if (mm && dd) {
			m = mm
			d = dd
		} else {
			m = date.getMonth() + 1
			d = date.getDate()
		}
		for (let i = 0; i < xztable.length; i++) {
			let data = xztable[i]
			if (m == data.start.month) {
				if (d >= data.start.day) {
					xz = data.name
					index = i
					break;
				}
			} else if (m == data.to.month) {
				if (d <= data.to.day) {
					xz = data.name
					index = i
					break;
				}
			}
		}
		return xztable[index]
	}
	/**
 * 启用键盘监听
 * @param keycode 需要监听的键盘码
 * @param down_f 按下的事件
 * @param up_f 弹起的事件
 */
	public static key_listener(keycode: number, down_f: Function, up_f: Function) {
		document.addEventListener("keydown", (e: any) => {
			if (e.keyCode == keycode) {
				down_f()
			}
		}, true)
		document.addEventListener("keyup", (e: any) => {
			if (e.keyCode == keycode) {
				up_f()
			}
		}, true)
	}


	/**
	 * 输入一个值，返回其数据类型
	 * "String" "Number" "Object" "Function" ...
	 * @param para 需要判断类型的数据
	 */
	public static get_type(para: any) {
		return Object.prototype.toString.call(para).slice(8, -1)
	}


	/**
	 * 数组去重
	 * 返回去重后的数组
	 * @param arr 要去重的数组
	 */
	public static getNo_list(arr: any[]) {
		var resultArr = [arr[0]];
		for (let i = 0; i < arr.length; i++) {
			let k = 0
			for (let j = 0; j < resultArr.length; j++) {
				if (arr[i] == resultArr[j]) {
					k = 1
					break
				}
			}
			if (k == 0) {
				resultArr.push(arr[i])
			}
		}
		return resultArr;
	}


	/**
	 * 
	 * @param arr 数组排序 从小到大
	 * @param type 默认为0表示从小到大 1为从大到小
	 * @param key 数组类型为obj时需要传入，被比较的字段
	 */
	public static list_isBig(arr: Array<never>, type = 0, key: string = "") {
		let datatype = Object.prototype.toString.call(arr[0]).slice(8, -1)
		if (datatype == "Number") {
			for (var j = 0; j < arr.length - 1; j++) {
				//两两比较，如果前一个比后一个大，则交换位置。
				for (var i = 0; i < arr.length - 1 - j; i++) {
					if (arr[i] > arr[i + 1]) {
						var temp = arr[i];
						arr[i] = arr[i + 1];
						arr[i + 1] = temp;
					}
				}
			}
			if (type == 1) {
				let r: any[] = []
				for (let i = arr.length - 1; i >= 0; i--) {
					r.push(arr[i])
				}
				return r
			}
			return arr
		} else if (datatype == "Object") {
			for (var j = 0; j < arr.length - 1; j++) {
				//两两比较，如果前一个比后一个大，则交换位置。
				for (var i = 0; i < arr.length - 1 - j; i++) {
					if (arr[i][key] > arr[i + 1][key]) {
						var temp = arr[i];
						arr[i] = arr[i + 1];
						arr[i + 1] = temp;
					}
				}
			}
			if (type == 1) {
				let r: any[] = []
				for (let i = arr.length - 1; i >= 0; i--) {
					r.push(arr[i])
				}
				return r
			}
			return arr
		}
	}

	/**
	 * 格式校验
	 * @param str 需要校验的字符串
	 * @param type  校验类型
	 */
	public static checkStr(str: string, type: string) {
		switch (type) {
			case 'phone':  //手机号码
				return /^1[3|4|5|6|7|8|9][0-9]{9}$/.test(str);
			case 'tel':   //座机
				return /^(0\d{2,3}-\d{7,8})(-\d{1,4})?$/.test(str);
			case 'card':  //身份证
				return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(str);
			case 'pwd':   //密码以字母开头，长度在6~18之间，只能包含字母、数字和下划线
				return /^[a-zA-Z]\w{5,17}$/.test(str)
			case 'postal': //邮政编码
				return /[1-9]\d{5}(?!\d)/.test(str);
			case 'QQ':   //QQ号
				return /^[1-9][0-9]{4,9}$/.test(str);
			case 'email':  //邮箱
				return /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(str);
			case 'money':  //金额(小数点2位)
				return /^\d*(?:\.\d{0,2})?$/.test(str);
			case 'URL':   //网址
				return /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/.test(str)
			case 'IP':   //IP
				return /((?:(?:25[0-5]|2[0-4]\\d|[01]?\\d?\\d)\\.){3}(?:25[0-5]|2[0-4]\\d|[01]?\\d?\\d))/.test(str);
			case 'date':  //日期时间
				return /^(\d{4})\-(\d{2})\-(\d{2}) (\d{2})(?:\:\d{2}|:(\d{2}):(\d{2}))$/.test(str) || /^(\d{4})\-(\d{2})\-(\d{2})$/.test(str)
			case 'number': //数字
				return /^[0-9]$/.test(str);
			case 'english': //英文
				return /^[a-zA-Z]+$/.test(str);
			case 'chinese': //中文
				return /^[\u4E00-\u9FA5]+$/.test(str);
			case 'lower':  //小写
				return /^[a-z]+$/.test(str);
			case 'upper':  //大写
				return /^[A-Z]+$/.test(str);
			case 'HTML':  //HTML标记
				return /<("[^"]*"|'[^']*'|[^'">])*>/.test(str);
			default:
				return true;
		}
	}


	/**
	 * 身份证格式校验
	 * @param sId 身份证号码
	 */
	public static isCardID(sId: string) {
		if (!/(^\d{15}$)|(^\d{17}(\d|X|x)$)/.test(sId)) {
			alert('你输入的身份证长度或格式错误')
			return false
		}
		//身份证城市
		var aCity: any = { 11: "北京", 12: "天津", 13: "河北", 14: "山西", 15: "内蒙古", 21: "辽宁", 22: "吉林", 23: "黑龙江", 31: "上海", 32: "江苏", 33: "浙江", 34: "安徽", 35: "福建", 36: "江西", 37: "山东", 41: "河南", 42: "湖北", 43: "湖南", 44: "广东", 45: "广西", 46: "海南", 50: "重庆", 51: "四川", 52: "贵州", 53: "云南", 54: "西藏", 61: "陕西", 62: "甘肃", 63: "青海", 64: "宁夏", 65: "新疆", 71: "台湾", 81: "香港", 82: "澳门", 91: "国外" };
		if (!aCity[parseInt(sId.substring(0, 2))]) {
			alert('你的身份证地区非法')
			return false
		}
		// 出生日期验证
		var sBirthday = (sId.substring(6, 10) + "-" + Number(sId.substring(10, 12)) + "-" + Number(sId.substring(12, 14))).replace(/-/g, "/"),
			d = new Date(sBirthday)
		if (sBirthday != (d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate())) {
			alert('身份证上的出生日期非法')
			return false
		}
		// 身份证号码校验
		var sum = 0,
			weights = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2],
			codes = "10X98765432"
		for (var i = 0; i < sId.length - 1; i++) {
			sum += Number(sId[i]) * weights[i];
		}
		var last = codes[sum % 11]; //计算出来的最后一位身份证号码
		if (sId[sId.length - 1] != last) {
			return false
		}
		return true
	}

	/**
 * 格式化时间
 * @param {time} 时间
 * @param {cFormat} 格式
 * @return {String} 字符串
 * @example formatTime('2018-1-29', '{y}/{m}/{d} {h}:{i}:{s}') // -> 2018/01/29 00:00:00
 */
	public static formatTime(time: Date, cFormat: string) {
		var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}', date
		date = new Date(time)
		var formatObj: any = {
			y: date.getFullYear(),
			m: date.getMonth() + 1,
			d: date.getDate(),
			h: date.getHours(),
			i: date.getMinutes(),
			s: date.getSeconds(),
			a: date.getDay()
		}
		var time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
			var value = formatObj[key]
			if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
			if (result.length > 0 && value < 10) {
				value = '0' + value
			}
			return value || 0
		})
		return time_str
	}

	/**
	 * 获取某月有多少天
	 * @param time 
	 */
	public static getMonthOfDay(time: any) {
		var date = new Date(time)
		var year = date.getFullYear()
		var mouth = date.getMonth() + 1
		var days
		//当月份为二月时，根据闰年还是非闰年判断天数
		if (mouth == 2) {
			days = (year % 4 == 0 && year % 100 == 0 && year % 400 == 0) || (year % 4 == 0 && year % 100 != 0) ? 28 : 29
		} else if (mouth == 1 || mouth == 3 || mouth == 5 || mouth == 7 || mouth == 8 || mouth == 10 || mouth == 12) {
			//月份为：1,3,5,7,8,10,12 时，为大月.则天数为31；
			days = 31
		} else {
			//其他月份，天数为：30.
			days = 30
		}
		return days
	}


	/**
	 * 获取某年有多少天
	 * @param time 
	 */
	public static getYearOfDay(time: any) {
		var firstDayYear = this.getFirstDayOfYear(time);
		var lastDayYear = this.getLastDayOfYear(time);
		var numSecond = (new Date(lastDayYear).getTime() - new Date(firstDayYear).getTime()) / 1000;
		return Math.ceil(numSecond / (24 * 3600));
	}


	/**
	 * 获取某年的第一天
	 * @param time 
	 */
	public static getFirstDayOfYear(time: any) {
		var year = new Date(time).getFullYear();
		return year + "-01-01 00:00:00";
	}


	/**
	 * 获取某年最后一天
	 * @param time 
	 */
	public static getLastDayOfYear(time: any) {
		var year = new Date(time).getFullYear();
		var dateString = year + "-12-01 00:00:00";
		var endDay = this.getMonthOfDay(dateString);
		return year + "-12-" + endDay + " 23:59:59";
	}


	/**
	 * 获取某个日期是当年中的第几天
	 * @param time 
	 */
	public static getDayOfYear(time: any) {
		var firstDayYear = this.getFirstDayOfYear(time);
		var numSecond = (new Date(time).getTime() - new Date(firstDayYear).getTime()) / 1000;
		return Math.ceil(numSecond / (24 * 3600));
	}


	/**
	 * 获取某个日期在这一年的第几周
	 * @param time 
	 */
	public static getDayOfYearWeek(time: any) {
		var numdays = this.getDayOfYear(time);
		return Math.ceil(numdays / 7);
	}


	/**
	 * 发送http请求
	 * @param setting
	 * @param example 
	 * 
	 **/
	public static send_http(setting: LeoHttp) {
		//设置参数的初始值
		var opts = {
			method: (setting.method || "GET").toUpperCase(), //请求方式
			url: setting.url || "", // 请求地址
			async: setting.async || true, // 是否异步
			dataType: setting.dataType || "json", // 解析方式
			data: setting.data || "", // 参数
			success: setting.success || function () { }, // 请求成功回调
			error: setting.error || function () { } // 请求失败回调
		}
		// 参数格式化
		function params_format(obj: any) {
			var str = ''
			for (var i in obj) {
				str += i + '=' + obj[i] + '&'
			}
			return str.split('').slice(0, -1).join('')
		}
		// 创建ajax对象
		var xhr = new XMLHttpRequest();
		xhr.withCredentials = false
		// 连接服务器open(方法GET/POST，请求地址， 异步传输)
		if (opts.method == 'GET') {
			xhr.open(opts.method, opts.url + "?" + params_format(opts.data), opts.async);
			xhr.send();
		} else {
			xhr.open(opts.method, opts.url, opts.async);
			xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
			xhr.send(opts.data);
		}
		/*
		** 每当readyState改变时，就会触发onreadystatechange事件
		** readyState属性存储有XMLHttpRequest的状态信息
		** 0 ：请求未初始化
		** 1 ：服务器连接已建立
		** 2 ：请求已接受
		** 3 : 请求处理中
		** 4 ：请求已完成，且相应就绪
		*/
		xhr.onreadystatechange = function () {
			if (xhr.readyState === 4 && (xhr.status === 200 || xhr.status === 304)) {
				switch (opts.dataType) {
					case "json":
						var json = (xhr.responseText)
						opts.success(json);
						break;
					case "xml":
						opts.success(xhr.responseXML);
						break;
					default:
						var json = (xhr.responseText)
						var d = (json.substring(0, json.indexOf("<")))
						opts.success(d);
						break;
				}
			}
		}
		xhr.onerror = function (err) {
			opts.error(err);
		}
	}
}
const toString = Object.prototype.toString;

export function is(val: unknown, type: string) {
	return toString.call(val) === `[object ${type}]`;
}

export function isDef<T = unknown>(val?: T): val is T {
	return typeof val !== 'undefined';
}

export function isUnDef<T = unknown>(val?: T): val is T {
	return !isDef(val);
}

export function isObject(val: any): val is Record<any, any> {
	return val !== null && is(val, 'Object');
}

export function isEmpty<T = unknown>(val: T): val is T {
	if (isArray(val) || isString(val)) {
		return val.length === 0;
	}

	if (val instanceof Map || val instanceof Set) {
		return val.size === 0;
	}

	if (isObject(val)) {
		return Object.keys(val).length === 0;
	}

	return false;
}

export function isDate(val: unknown): val is Date {
	return is(val, 'Date');
}

export function isNull(val: unknown): val is null {
	return val === null;
}

export function isNullAndUnDef(val: unknown): val is null | undefined {
	return isUnDef(val) && isNull(val);
}

export function isNullOrUnDef(val: unknown): val is null | undefined {
	return isUnDef(val) || isNull(val);
}

export function isNumber(val: unknown): val is number {
	return is(val, 'Number');
}

export function isPromise<T = any>(val: unknown): val is Promise<T> {
	return is(val, 'Promise') && isObject(val) && isFunction(val.then) && isFunction(val.catch);
}

export function isString(val: unknown): val is string {
	return is(val, 'String');
}

export function isFunction(val: unknown): val is Function {
	return typeof val === 'function';
}

export function isBoolean(val: unknown): val is boolean {
	return is(val, 'Boolean');
}

export function isRegExp(val: unknown): val is RegExp {
	return is(val, 'RegExp');
}

export function isArray(val: any): val is Array<any> {
	return val && Array.isArray(val);
}

export function isWindow(val: any): val is Window {
	return typeof window !== 'undefined' && is(val, 'Window');
}

export function isElement(val: unknown): val is Element {
	return isObject(val) && !!val.tagName;
}

export function isMap(val: unknown): val is Map<any, any> {
	return is(val, 'Map');
}

export const isServer = typeof window === 'undefined';

export const isClient = !isServer;

export function isUrl(path: string): boolean {
	const reg =
		/^(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?(\/#\/)?(?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
	return reg.test(path);
}
export function setObjToUrlParams(baseUrl: string, obj: any): string {
	let parameters = '';
	for (const key in obj) {
		parameters += key + '=' + encodeURIComponent(obj[key]) + '&';
	}
	parameters = parameters.replace(/&$/, '');
	return /\?$/.test(baseUrl) ? baseUrl + parameters : baseUrl.replace(/\/?$/, '?') + parameters;
}
// 深度合并
export function deepMerge<T = any>(src: any = {}, target: any = {}): T {
	let key: string;
	for (key in target) {
		src[key] = isObject(src[key]) ? deepMerge(src[key], target[key]) : (src[key] = target[key]);
	}
	return src;
}
