import { AppRouteRecordRaw } from "@/interface/interface"
import { UserLoginStruct } from "@/data/interface"

import Catch from "@/common/cache/cache"
import { ElLoading, ElMessage } from 'element-plus'
import { useRouter } from "vue-router"


class Config {
    // public static baseURL = "http://192.168.1.100:8199/api"
    // public static baseURL = "http://124.220.148.153:8199/api"

    public static baseURL = "http://www.leotest.site/api"
    public static homeURL = "http://www.leotest.site"

    public static UserInfo: UserLoginStruct | any
    public static MenuList: AppRouteRecordRaw[] = []
    public static IsLogin: boolean = false
    public static Catch = Catch
    public static ElMessage = ElMessage
    private static loading
    public static CheckoutLogin() {
        let token = this.Catch.get_item("TOKEN")
        let menulist = this.Catch.get_item("MenuList")
        if (token && menulist) {
            //    console.log(JSON.parse(menulist.value));

            this.MenuList = menulist.value as AppRouteRecordRaw[]
            this.IsLogin = true
            return true
        }
        this.IsLogin = false
        return false
    }
    public static GetNowPageTitle(): string {
        let nowmenu = this.Catch.get_item("NowMenu")
        for (let i = 0; i < this.MenuList.length; i++) {
            if (this.MenuList[i].component == nowmenu?.value) {
                return this.MenuList[i].meta.title as string
            }
            if (this.MenuList[i].children && this.MenuList[i].children.length > 0) {
                for (let j = 0; j < this.MenuList[i].children.length; j++) {
                    if (this.MenuList[i].children[j].component == nowmenu?.value) {
                        return this.MenuList[i].children[j].meta.title as string
                    }
                }
            }

        }

        return ""
    }
    public static GetNowPageMenu(): string {
        let nowmenu = this.Catch.get_item("NowMenu")
        // for (let i = 0; i < this.MenuList.length; i++) {
        //     if (this.MenuList[i].name == nowmenu?.value) {
        //         return this.MenuList[i].name as string
        //     }
        //     if (this.MenuList[i].children && this.MenuList[i].children.length > 0) {
        //         for (let j = 0; j < this.MenuList[i].children.length; j++) {
        //             if (this.MenuList[i].children[j].name == nowmenu?.value) {
        //                 return this.MenuList[i].children[j].name as string
        //             }
        //         }
        //     }

        // }

        return nowmenu?.value
    }
    public static Loading() {
        this.loading = ElLoading.service({
            lock: true,
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.7)',
        })
    }
    public static LoadingClose() {

        try {
            setTimeout(() => {
                this.loading.close()

            }, 500);
        } catch (e) {

        }
    }
    public static copy(data) {
        return navigator.clipboard.writeText(data)
    }
}
export default Config