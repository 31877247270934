import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Index from '../views/sys/index.vue'
import Login from '../views/sys/login.vue'
import DefIndex from '../views/mobile/index/index.vue'
import DefLogin from '../views/mobile/login/index.vue'
import DefMy from '../views/mobile/my/index.vue'



const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    component: DefIndex
  },
  {
    path: '/login',
    name: 'login',
    component: DefLogin
  },
  {
    path: '/my',
    name: 'my',
    component: DefMy
  },
  {
    path: '/admin',
    name: 'admin',
    component: Index
  },
  {
    path: '/admin/login',
    name: 'adminLogin',
    component: Login
  },
  {
    path: '/404',
    name: 'error404',
    component: () => import(/* webpackChunkName: "about" */ '@/views/sys/error_404.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
