import { defHttp } from "@/common/axios"
import { LoginStruct } from "./interface"
import { AppRouteRecordRaw } from "@/interface/interface"
import Config from "@/data/config"
import axios, { Axios } from "@/plugin/axios"
const AdminApi = {
  login: "/admin/login",
  login_out: "/admin/logout",
  getMenuList: "/admin/getMenuList",
  changePwd: "/admin/changePwd",
  lookPassword: '/lookPassword',
  compressImageList: '/compressImage/list',
  checkTask: '/checkTask',
  admin: "/admin",
  file: '/file', //文件上传
}
const UserApi = {
  gameList: "/user/gameList",
  login: "/user/login",
  accessGame: "/user/accessGame"

}
export function Logout() {
  return defHttp.get({ url: AdminApi.login_out })
}
export function Login(param: LoginStruct) {
  return defHttp.post(
    {
      url: AdminApi.login,
      params: param
    }
    , { isTransformResponse: false }
  )
}
export function GetMenuList() {
  return defHttp.get<AppRouteRecordRaw[]>({ url: AdminApi.getMenuList })
}
export function CompressImageList() {
  return defHttp.get<any[]>({ url: AdminApi.compressImageList })
}
export function CheckTask(params) {
  return defHttp.get<any[]>({ url: AdminApi.checkTask, params })
}
export const Admin = (params) => {
  return defHttp.post({ url: AdminApi.admin, params }, { errorMessageMode: "modal" });
};
export const lookPassword = (params) => {
  return defHttp.post(
    { url: AdminApi.lookPassword, params },
    {
      errorMessageMode: 'modal',
      isTransformResponse: false,
    },
  );
};

export const ChangePwd = (params) => {
  return defHttp.post(
    { url: AdminApi.changePwd, params },
    {
      errorMessageMode: 'modal',
      isTransformResponse: false,
    },
  );
};

export const file = (params) => {
  return defHttp.get({ url: AdminApi.file, params });
};
export const GetCompressImg = (fname) => {
  return defHttp.get({ url: AdminApi.file + "?fname=python/" + fname });
};

export const GetGameList = () => {
  return defHttp.get({ url: UserApi.gameList });
};
export const UserLogin = (account: string, pwd: string, yzm: string) => {
  return defHttp.post({ url: UserApi.login, params: { account: account, password: pwd, yzm: yzm } }, { isTransformResponse: false, });
};
export const AccessGame = (data) => {
  return axios.get(Config.baseURL+UserApi.accessGame, { params: data, headers: { "Token": Config.UserInfo.token } })
};