
import { ref, defineComponent, onMounted, watch } from 'vue';
import type { MyOptions } from '@/data/interface';
import Upload from './upload.vue';
import UploadArray from './uploadArray.vue';
import Editor from './editor.vue';
import Data from '@/data/data';
import Icon from "./icon.vue"
export default defineComponent({
  components: { Upload, Editor, UploadArray, Icon },
  props: {
    lab: {
      type: String,
      default: '',
    },
    labw: {
      type: String,
      default: '120px',
    },
    wid: {
      type: String,
      default: '200px',
    },
    inputType: {
      type: String,
      default: 'string',
    },
    index: {
      type: Number,
      default: -1,
    },
    itemkey: {
      type: String,
      default: "",
    },
    val: {
      type: String || Number || Boolean,
      default: '',
    },
    updateval: {
      type: Function,
      default: () => { },
    },
    remote: {
      type: Function,
      default: () => { },
    },
    options: {
      type: Object,
      default: [],
    },
    validok: {
      type: Boolean,
      default: true,
    },
    valid: {
      type: String,
      default: 'empty',
    },
    disable: {
      type: Boolean,
      default: false,
    },
    isSearch: { //是否是搜索用
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const visible = ref(false);
    const validcontent = ref('');
    if (props.valid == 'empty') {
      validcontent.value = '该字段不能为空！';
    } else if (props.valid == 'zero') {
      validcontent.value = '该字段不能为0！';
    } else if (props.valid == 'int') {
      validcontent.value = '该字段为整数';
    }
    const loading = ref(false)
    const value = ref(props.val);
    const swvalue = ref(Boolean(props.val));
    const svalue = ref(props.val);
    const svalues = ref(props.val);
    const nvalue = ref(props.val);

    nvalue.value = Number(nvalue.value).toFixed(3)
    const tvalue = ref(props.val);
    const myicon = ref(props.val);
    const radioValue = ref(props.val);
    const iconimage = ref(props.val);
    const shortcuts = [
      {
        text: '今天',
        value: new Date(),
      },
      {
        text: '昨天',
        value: () => {
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24);
          return date;
        },
      },
      {
        text: '一周前o',
        value: () => {
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
          return date;
        },
      },
    ];
    if (myicon.value == '' || props.val == '') {
      myicon.value = 'ant-design:account-book-filled';
    }

    const imgArr = ref<String[]>([])
    imgArr.value = []
    if (iconimage.value && typeof (iconimage.value) == "string") {
      // console.log(iconimage.value);

      imgArr.value = iconimage.value.split(",")

    }
    onMounted(() => { });
    function onChange(v) {
      if (v == null && props.inputType == 'number') {
        nvalue.value = '0';
      }

      props.updateval(props.index, v, props.itemkey);
    }
    function onChangetime() {
      props.updateval(props.index, tvalue.value, props.itemkey);
    }
    function aonChange() {
      props.updateval(props.index, value.value, props.itemkey);
    }
    function imageLoadError() {
      console.log('image error');
    }
    watch(() => props.options, () => {
      loading.value = false
    }, { deep: true })
    function remoteMethod(query) {
      loading.value = true
      props.remote(props.index, query);
    }
    return {
      remoteMethod,
      visible,
      Data,
      value,
      svalue,
      svalues,
      nvalue,
      swvalue,
      tvalue,
      radioValue,
      myicon,
      iconimage,
      onChange,
      onChangetime,
      aonChange,
      shortcuts,
      validcontent,
      imageLoadError,
      imgArr,
      loading
    };
  },
  watch: {
    val(n) {
      this.myicon = n;
      this.value = n;
      this.nvalue = n;
      this.svalue = n;
      this.swvalue = n;
      this.tvalue = n;
      this.radioValue = n;
      this.iconimage = n;

      // console.log(n, 'value ');
    },
  },
});
