export interface LeoWebSocket {
    IsConnect: boolean,
    OnMsg: string[],
    Ws: WebSocket,
    Url: string,
    CallBack: Function[],
    OnOpen: Function,
    On: Function,
    Send: Function,
    KeepConnect: Function,
    KeepTimerID: number
}
export interface LeoWebSocketConfig{
    keeplive:boolean
    time:number,
    timer:number
}
export enum HttpMethod {
    POSt = "POST",
    GET = "GET",
    PUT = "PUT",
    DELETE = "DELETE"
}
export enum HttpDataType {
    JSON = "json",
    XML = "xml",
}
export interface LeoHttp {
    method?: HttpMethod, //请求方式
    url: string, // 请求地址
    async?: boolean, // 是否异步
    dataType?: string, // 解析方式
    data?: any, // 参数
    success?: Function, // 请求成功回调
    error?: Function// 请求失败回调
}
export type ErrorMessageMode = 'none' | 'modal' | 'message' | undefined;

export interface RequestOptions {
    // Splicing request parameters to url
    joinParamsToUrl?: boolean;
    // Format request parameter time
    formatDate?: boolean;
    // Whether to process the request result
    isTransformResponse?: boolean;
    // Whether to return native response headers
    // For example: use this attribute when you need to get the response headers
    isReturnNativeResponse?: boolean;
    // Whether to join url
    joinPrefix?: boolean;
    // Interface address, use the default apiUrl if you leave it blank
    apiUrl?: string;
    // 请求拼接路径
    urlPrefix?: string;
    // Error message prompt type
    errorMessageMode?: ErrorMessageMode;
    // Whether to add a timestamp
    joinTime?: boolean;
    ignoreCancelToken?: boolean;
    // Whether to send token in header
    withToken?: boolean;
    // 请求重试机制
    retryRequest?: RetryRequest;
}

export interface RetryRequest {
    isOpenRetry: boolean;
    count: number;
    waitTime: number;
}
export interface Result<T = any> {
    code: number;
    type: 'success' | 'error' | 'warning';
    message: string;
    result: T;
}

// multipart/form-data: upload file
export interface UploadFileParams {
    // Other parameters
    data?: any;
    // File parameter interface field name
    name?: string;
    // file name
    file: File | Blob;
    // file name
    filename?: string;
    [key: string]: any;
}
/**
 * @description: Request result set
 */
export enum ResultEnum {
    SUCCESS = 0,
    ERROR = -1,
    TIMEOUT = 401,
    TYPE = 'success',
}

/**
 * @description: request method
 */
export enum RequestEnum {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
}

/**
 * @description:  contentType
 */
export enum ContentTypeEnum {
    // json
    JSON = 'application/json;charset=UTF-8',
    // form-data qs
    FORM_URLENCODED = 'application/x-www-form-urlencoded;charset=UTF-8',
    // form-data  upload
    FORM_DATA = 'multipart/form-data;charset=UTF-8',
}
export enum SessionTimeoutProcessingEnum {
    ROUTE_JUMP,
    PAGE_COVERAGE,
}
export interface CacheConfigOption {
    prefix: string,
    storagepool: string
    check_expire_time: number,//检查过期时间
    expire_time: number,//过期时间 秒
}
export interface CacheData {
    key: string,
    value: any,
    exp_time: number,
    create_time: number,
}

import type { RouteRecordRaw, RouteMeta } from 'vue-router';
import { defineComponent } from 'vue';

export interface LoginStruct {
    username: string,
    password: string
}
export type Component<T = any> =
  | ReturnType<typeof defineComponent>
  | (() => Promise<typeof import('*.vue')>)
  | (() => Promise<T>);

export interface AppRouteRecordRaw {
    name: string;
    meta: RouteMeta;
    component?: Component | string;
    components?: Component;
    children: AppRouteRecordRaw[];
    props?: any;
    fullPath?: string;
  }
  export interface Menu {
    name: string;
  
    icon?: string;
  
    path: string;
  
    // path contains param, auto assignment.
    paramPath?: string;
  
    disabled?: boolean;
  
    children?: Menu[];
  
    orderNo?: number;
  
    meta?: Partial<RouteMeta>;
  
    hideMenu?: boolean;
  }
  export type AppRouteModule = AppRouteRecordRaw;
  
