import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Vant from "vant"
import Element from "element-plus"
import 'vant/lib/index.css';
import "element-plus/dist/index.css"

const app = createApp(App)
app.use(router).use(Vant).use(Element).mount('#app')
app.config.warnHandler = () => null

