import { file, lookPassword, GetMenuList } from '@/api/api';
import Config from "./config"
export function tuenParam(d, edit = false) {
  let ad: { [key: string]: boolean | number | string } = {};
  for (let i = 0; i < d.length; i++) {
    if (edit == false) {
      if (d[i]['value'] !== '' && d[i]['value'] !== null && d[i]['value'] !== 'null') {
        ad[d[i]['field']] = d[i]['value'];
      }
    } else if (d[i]['updata']) {
      ad[d[i]['field']] = d[i]['value'];
      if (d[i]['field'] == "games") {
        let s = ""
        for (let q = 0; q < d[i]['value'].length; q++) {
          if(s!=""){
            s+=","
          }
          s += d[i]['value'][q]
        }
        ad[d[i]['field']] = s
      }
    }
  }
  return ad;
}
export function stringToArr(d: string, s = ',') {
  let a = d.split(s);
  return a;
}
export function arrToString(d, s = ',') {
  let rs = '';
  for (let i = 0; i < d.length; i++) {
    rs += d[i];
    if (i + 1 != d.length) {
      rs += s;
    }
  }
  return rs;
}
export async function getImage(url) {
  let d = await file({ fname: url });
  return d;
}
/**
 *
 * @param timesteamp 秒
 * @param f
 * @returns
 */
export function timesteampToTime(timesteamp: number, f = '-'): string {
  let time = timesteamp ? timesteamp * 1000 : 0;
  if (time == 0) {
    return '无';
  }
  let date = new Date(time);
  let Y = date.getFullYear();
  let M: any = date.getMonth() + 1;
  let D: any = date.getDate();
  let h: any = date.getHours();
  let m: any = date.getMinutes();
  let s: any = date.getSeconds();
  if (M < 10) {
    M = 0 + '' + M
  }
  if (D < 10) {
    D = 0 + '' + D
  }
  if (h < 10) {
    h = 0 + '' + h
  }
  if (m < 10) {
    m = 0 + '' + m
  }
  if (s < 10) {
    s = 0 + '' + s
  }
  return Y + f + M + f + D + ' ' + h + ':' + m + ':' + s;
}

export async function enCodeBase64(id: number, data: string) {
  let rd = data;
  var d = await lookPassword({ pwd: rd, uid: id });
  return d;
}


export function Addroute(router, routers: any) {
  routers.filter(itemRouter => {
    if (itemRouter.component != "LAYOUT") {
      let path = itemRouter.path
      if (path[0] != "/") {
        path = "/" + path
      }
      router.addRoute({
        path: `${path}`,
        name: itemRouter.name,
        component: () => import('@/views' + itemRouter.component)
      });
      // console.log(itemRouter.component);

    }
    // 是否存在子集
    if (itemRouter.children && itemRouter.children.length) {
      Addroute(router, itemRouter.children);
    }
    return true;
  });
};

export function GetComponent(path: string) {
  if (path == "") {
    return
  }
  try {
    return require('@/views' + path).default
  } catch (e) {
    return require('@/views/sys/error_404.vue').default

  }
  // return require('@/components/MyTable/table.vue').default

}
export function GetComponentByName(name: string) {
  let path = ""
  for (let i = 0; i < Config.MenuList.length; i++) {

    if (Config.MenuList[i].name == name) {
      path = Config.MenuList[i].component
      break;
    }
    if (Config.MenuList[i].children) {
      for (let j = 0; j < Config.MenuList[i].children.length; j++) {
        if (Config.MenuList[i].children[j].name == name) {
          path = Config.MenuList[i].children[j].component
          break;
        }
      }
      if (path != "") {
        break
      }
    }

  }
  // console.log(path, name, Config.MenuList);

  try {
    return require('@/views' + path).default
  } catch (e) {
    return require('@/views/sys/error_404.vue').default

  }
}
export function getMenuList() {
  return new Promise((rj, re) => {
    try {
      let menuList: any = []
      GetMenuList().then(routes => {
        menuList.push(
          {
            path: "/index",
            name: "index",
            component: "/index.vue",
            meta: {
              title: "首页"
            }
          }
        )
        for (let i = 0; i < routes.length; i++) {
          // console.log(routes[i].meta);

          if (!routes[i].meta["hideMenu"]) {
            menuList.push(routes[i])
          }
        }
        Config.MenuList = menuList
        Config.Catch.set_item("MenuList", JSON.stringify(Config.MenuList))
        rj(true)
      })
    } catch (e) {
      re(false)
    }
  })
  // Addroute(router, routes)
  // console.log(router.getRoutes(), ">>>")

}
